import React from 'react'

export default function App(props) {

    return (
        <>
            <main>
                <div className="hero">
                    <h1>Tribe Gaming</h1>
                </div>
                <div className="intro">
                    <div className="content">
                        <h2>Qui sommes nous ?</h2>
                        <p>
                        Nous sommes une communautée grandissante qui oeuvre dans l'univers des jeux vidéos.<br />
                        Nous cherchons des profils de joueurs qui sont dans la démarche de jouer en groupe.
                        </p>
                        <p>
                        La structure est relativement récente par rapport à d'autres communautées.<br/>
                        Mais nous avons l'envis de construire dans le temps un projet structuré qui tiendra sur la distance.<br />
                        Nous acceptons tous type de profil compétitif ou plus casual.<br />
                        Nos équipes eSport ont pour objectif de monter en niveau et de performer au plus haut niveau possible.
                        </p>
                    </div>
                </div>
                <div className="why-tribe">
                    <div className="content">
                        <h2>Pourquoi Tribe ?</h2>
                        <p>"Tribe" (tribu) reflète parfaitement l'état d'esprit du projet.<br />
                        Nous sommes une communauté à tendance anarchiste (cad sans hiérachie prononcer), tous membres a son mot à dire.</p>
                        <p>Avec cette idée nous acceptons toute personne qui se trouve dans cet état d'esprit.<br />
                        La porte est ouverte à tous les joueurs même casual cherchant avant tout un grand bande de "potes" pour jouer ensemble.</p>
                        <p>Chez nous l'IRL est une priorité même au sein de nos équipes compétitives.</p>
                        <p>Sur cette base notre objectif est de progresser le plus loin possible tous ensembles dans la bonne humeur.</p>
                    </div>
                </div>
                <div className="games-list">
                    <div className="content">
                        <h2>Quel jeux chez tribe ?</h2>
                        <p>Rien n'est fixe dans le sens ou des jeux peuvent être ajoutés ou retirés.</p>

                        <p>Nous avons actuellement dans la liste league of legend, overwatch, apex,
                        destiny2, counter-strike GO, sea of thieves, battlefront 2 ...</p>

                        <p>Sachant que la communauté décide des jeux courants, nous n'excluons aucun jeu
                        du moment que ces jeux soient actifs.</p>

                        <p>Nous avons aussi des soirées thématiques sur des jeux précis qui sont en général au minimum "streamé".<br />
                        Des soirées tournois internes avec de lots seront mis en place par la suite pour impliquer la communauté.</p>
                        <p>Actuellement nous avons presque exclusivement des jeux coop, <br />
                        mais des jeux massifs (mmo) pourraient parfaitement être envisagé à la condition d'avoir l'effectif sur ce type de jeu.</p>

                        <div className="logos-game">
                            <svg viewBox="0 0 600 600">
                                <path d="M 296.70405,0.00424149 C 229.93779,0.43221149 163.9322,24.232681 112.07437,66.087451 l 56.66164,65.785689 C 216.5573,94.134131 280.99173,78.755181 340.75427,90.636091 c 32.84344,6.31836 64.09548,20.739679 90.46333,41.237049 L 487.87924,66.087451 C 434.24075,22.804341 365.76667,-1.0306785 296.70405,0.00424149 z" />
                                <path d="M 93.627932,82.252881 C 33.924062,138.34244 -1.0821983,219.87731 0.02552166,302.12246 0.06092166,387.49363 39.004212,471.35277 103.6776,526.81868 c 59.78108,52.2845 141.08577,78.91882 220.46136,72.18109 86.01035,-6.40518 167.64782,-52.34458 218.11716,-122.14284 49.12454,-66.32853 68.37083,-153.7971 52.06401,-234.90882 C 582.33252,179.85678 549.77957,122.31301 503.48268,79.542111 L 446.82104,145.3278 c 45.65578,43.03442 70.58508,106.55713 65.88486,169.22459 -2.05604,31.04856 -11.0736,61.69033 -26.31427,88.95944 l -115.47207,-111.53814 -58.26,-125.58266 -0.0876,190.17825 116.69522,112.92659 c -52.0019,40.40208 -123.35295,53.80249 -186.57773,35.89403 -25.74389,-7.18124 -50.12185,-19.23034 -71.30879,-35.39816 L 288.86927,356.56928 c -0.20481,-61.83078 0.7175,-128.57681 -6.9e-4,-190.3826 L 230.52166,291.97369 114.05785,404.43746 c -35.530468,-62.02488 -36.380378,-142.2105 -2.18183,-204.92738 10.69103,-20.0919 24.84106,-38.42801 41.25655,-54.18228 L 96.470932,79.542111 c -0.94767,0.90359 -1.89534,1.80717 -2.843,2.71077 z" />
                            </svg>
                            <img src={ require("./assets/league-of-legends.png") } alt="league of legends" />
                            <img src={ require("./assets/Tom_Clancy's_Rainbow_Six_Siege_Logo.png") } alt="rainbowsix | siege" />
                            <img src={ require("./assets/apex-legends-symbol-png-transparent.png") } alt="Apex" />
                            <img src={ require("./assets/fortnite.png") } alt="fortnite" />
                            <img src={ require("./assets/battlefront2.png") } alt="battlefront 2" />
                            <img src={ require("./assets/counter-strike-global-offensive-logo-B877EB5D33-seeklogo.com.png") } alt="CS GO" />
                            <img src={ require("./assets/169-1691567_thief-vector-theif-sea-of-thieves-logo-black.png") } alt="Sea of thieves" />
                            <img src={ require("./assets/Destiny_Logo_2.png") } alt="Destiny 2" />
                        </div>

                    </div>
                </div>
                <div className="project">
                    <div className="content">
                        <h2>Projets autour de tribe</h2>
                        <p>A ce jour nos projets tournent autour de la mise en place d'une structure solide.<br />
                        Une fois la structure en place nous pourrons proposer plus de services et certainement d'autre jeux.<br />
                        Nous sommes dans l'objectif de progresser petit à petit pour une monter en puissance
                        (baby steps comme diraient nos amis anglophone).</p>

                        <p>Nous avons monté une chaine youtube ou n'importe quel membre pourra
                        uploader ses vidéos dessus.<br />
                        Vous l'aurez compris c'est une chaine strictement communautaire</p>

                        <p>Un 2eme site sera mis en place pour présenter nos équipes compétitives.<br />
                        Voir organiser peut être un championnat si c'est envisageable.</p>
                    </div>
                </div>
            </main>
            <footer>
                <div className="content text-center">
                    <a href="https://discord.gg/VHM2gAd" target="_blank" rel="noopener noreferrer">
                        <svg viewBox="0 0 24 24">
                            <path d="m3.58 21.196h14.259l-.681-2.205 1.629 1.398 1.493 1.338 2.72 2.273v-21.525c-.068-1.338-1.22-2.475-2.648-2.475l-16.767.003c-1.427 0-2.585 1.139-2.585 2.477v16.24c0 1.411 1.156 2.476 2.58 2.476zm10.548-15.513-.033.012.012-.012zm-7.631 1.269c1.833-1.334 3.532-1.27 3.532-1.27l.137.135c-2.243.535-3.26 1.537-3.26 1.537s.272-.133.747-.336c3.021-1.188 6.32-1.102 9.374.402 0 0-1.019-.937-3.124-1.537l.186-.183c.291.001 1.831.055 3.479 1.26 0 0 1.844 3.15 1.844 7.02-.061-.074-1.144 1.666-3.931 1.726 0 0-.472-.534-.808-1 1.63-.468 2.24-1.404 2.24-1.404-.535.337-1.023.537-1.419.737-.609.268-1.219.4-1.828.535-2.884.468-4.503-.315-6.033-.936l-.523-.266s.609.936 2.174 1.404c-.411.469-.818 1.002-.818 1.002-2.786-.066-3.802-1.806-3.802-1.806 0-3.876 1.833-7.02 1.833-7.02z"/>
                            <path d="m14.308 12.771c.711 0 1.29-.6 1.29-1.34 0-.735-.576-1.335-1.29-1.335v.003c-.708 0-1.288.598-1.29 1.338 0 .734.579 1.334 1.29 1.334z"/>
                            <path d="m9.69 12.771c.711 0 1.29-.6 1.29-1.34 0-.735-.575-1.335-1.286-1.335l-.004.003c-.711 0-1.29.598-1.29 1.338 0 .734.579 1.334 1.29 1.334z"/>
                        </svg>
                    </a>
                    <a href="https://www.twitch.tv/tribe_gamingfr" target="_blank" rel="noopener noreferrer">
                        <svg viewBox="0 0 24 24">
                            <path d="m.975 4.175v16.694h5.749v3.131h3.139l3.134-3.132h4.705l6.274-6.258v-14.61h-21.434zm3.658-2.09h17.252v11.479l-3.66 3.652h-5.751l-3.134 3.127v-3.127h-4.707z"/>
                            <path d="m10.385 6.262h2.09v6.26h-2.09z"/>
                            <path d="m16.133 6.262h2.091v6.26h-2.091z"/>
                        </svg>
                    </a>
                    <a href="https://twitter.com/EsportTribe" target="_blank" rel="noopener noreferrer">
                        <svg viewBox="0 0 512 512">
                            <path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
                                c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
                                c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
                                c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
                                c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
                                c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
                                C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
                                C480.224,136.96,497.728,118.496,512,97.248z"/>
                        </svg>
                    </a>
                </div>
            </footer>
        </>
    )
}